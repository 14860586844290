import React from 'react';
import { useTranslation } from "react-i18next"

import { Container } from '../../components/container';
import SEO from '../../components/seo';
import Jobs from './jobItem';
import styles from './jobs.module.css';
import teamImg from './team.jpg';

const JobsPage = () => {
  const { t } = useTranslation();

  return (
  <>
    <SEO title="TUN, WAS SIE WOLLEN" />
    <Container>
      <div className={styles.terms}>
        <h3 className={styles.textHeader}>{t("careers.title")}</h3>
        {t("careers.subtitle")}
        <br />
        {t("careers.text")}
      </div>
      <div className={styles.teamImgWrap}>
        <img src={teamImg} className={styles.teamImg} alt="team image" />
      </div>
      <div className={styles.textSubHeader}>{t("careers.listTitle")}</div>
      <Jobs />
    </Container>
  </>
  )
}

export default JobsPage
